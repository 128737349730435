.thumb {
  cursor: pointer;
  transition: -webkit-transform .5s, transform .5s, opacity .5s;
}

.thumb:nth-child(2n+1) {
  margin-bottom: 90px;
}

.thumb__actions {
  width: 100%;
  color: inherit;
  text-decoration: none;
  display: block;
}

.thumb__image {
  margin-bottom: .75rem;
  position: relative;
  box-shadow: 0 .375rem .75rem rgba(26, 25, 25, .16);
}

.thumb__image:after {
  content: "";
  width: 100%;
  height: 100%;
  opacity: .2;
  -webkit-filter: invert();
  filter: invert();
  background: currentColor;
  position: absolute;
  top: 0;
  left: 0;
}

.thumb__image img {
  width: 100%;
}

.thumb__title {
  --data: attr(data-count);
  --index: var(--data);
  margin-bottom: .625rem;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.1667;
  position: relative;
}

.thumb__title:before {
  content: "0" var(--index) " / ";
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 767.98px) {
  .thumb__title {
    font-size: 1.75rem;
  }

  .thumb__title:before {
    font-size: 1.5rem;
  }
}

.thumb__entry {
  max-width: 16.0625rem;
  margin-bottom: .9375rem;
}

/*# sourceMappingURL=index.9ece1afa.css.map */
