@import '../variables';
@import '../mixins';

/* ------------------------------------------------------------ *\
	Thumb
\* ------------------------------------------------------------ */

.thumb {
	cursor: pointer;
	transition: transform .5s, opacity .5s;

	&:nth-child(2n+1) {
		margin-bottom: 90px;
	}

	&__actions {
		display: block;
		width: 100%;
		text-decoration: none;
		color: inherit;
	}

	&__image {
		position: relative;
		margin-bottom: rem(12px);
		box-shadow: 0 rem(6px) rem(12px) rgba($body-color, .16);

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: currentColor;
			opacity: .2;
			filter: invert(1);
		}

		img {
			width: 100%;
		}
	}

	&__title {
		font-size: rem(36px);
		line-height: 1.1667;
		position: relative;
		margin-bottom: rem(10px);
		font-weight: 700;
		--data: attr(data-count);
		--index: var(--data);

		&:before {
			content: '0' var(--index)' / ';

			font-size: rem(24px);
			font-weight: 500;
		}

		@include media-breakpoint-down(sm) {
			& {
				font-size: rem(28px);
			}
			&:before {
				font-size: rem(24px);
			}
		}
	}

	&__entry {
		max-width: rem(257px);
		margin-bottom: rem(15px);
	}
}
